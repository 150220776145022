<template>
	<div class="flex w-fit flex-col" :class="props.color">
		<input
			:id="props.id"
			v-model="checked"
			class="sr-only"
			type="checkbox"
			:disabled="props.disabled"
		/>

		<div class="flex items-center">
			<label
				:for="props.id"
				:class="[
					'options-label relative min-h-[1.25rem]',
					props.disabled ? 'opacity-50' : 'cursor-pointer',
					props.label ? 'pl-8' : 'pl-5',
				]"
				>{{ props.label }}</label
			>
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	id: String,
	label: {
		type: String,
		default: null,
	},
	modelValue: Boolean,
	checked: {
		type: [Array, Boolean],
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	color: {
		type: String,
		default: 'primary',
	},
	value: {
		type: String,
		default: null,
	},
});

const checked = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	},
});

const emit = defineEmits(['update:modelValue']);
</script>

<style lang="postcss" scoped>
label::before {
	content: '';
	left: 0;
	top: 50%;
	height: 1.125rem;
	width: 1.125rem;
	transform: translateY(-50%);

	@apply absolute rounded border-2;
}

input:checked + div {
	label::before {
		@apply bg-icon-check bg-[length:0.938rem_0.625rem] bg-center bg-no-repeat;
	}
}

.primary {
	label::before {
		@apply border-primary;
	}

	input:checked + div {
		label::before {
			@apply bg-primary;
		}
	}
}
</style>
