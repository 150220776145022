<template>
	<div>
		<span class="font-primary font-normal">
			<slot name="text" />
		</span>
		<a :id="props.id" :href="props.href" :class="anchorClasses">
			<slot />
		</a>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	id: String,
	href: String,
	disabled: {
		type: Boolean,
		default: false,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	variant: {
		type: String,
		default: 'elevated',
	},
});

const classes = {
	base: 'font-primary font-bold transition ease-in-out duration-150 !cursor-pointer',
	disabledBase: 'cursor-not-allowed',
	variant: {
		elevated: '!text-primary',
		elevatedShadow: '',
		outlined: '',
		outlinedLight: '',
		outlinedDestructive: '',
		dashed: '',
		text: '',
	},
	hover: {
		elevated: 'hover:!text-dark-primary',
		elevatedShadow: '',
		outlined: '',
		outlinedLight: '',
		outlinedDestructive: '',
		dashed: '',
		text: '',
	},
	disabled: {
		elevated: '!text-neutral-gray text-neutral-black opacity-50',
		elevatedShadow: '',
		outlined: '',
		outlinedLight: '',
		outlinedDestructive: '',
		dashed: '',
		text: '',
	},
	active: {
		elevated: 'active:!text-darkest-primary',
		elevatedShadow: '',
		outlined: '',
		outlinedLight: '',
		outlinedDestructive: '',
		dashed: '',
		text: '',
	},
};

const anchorClasses = computed(() => [
	classes.base,
	classes.variant[props.variant],
	{
		[classes.disabledBase]: props.disabled,
		[classes.disabled[props.variant]]: props.disabled,
		[classes.hover[props.variant]]: !props.disabled && !props.isLoading,
		[classes.active[props.variant]]: !props.disabled && !props.isLoading,
	},
]);
</script>
